import React from 'react';
import { graphql } from 'gatsby'


const AgencySingle = ({ data }) => {
  console.log(data);
  return <div>THIS IS AGENCY SINGLE!</div>
}
export default AgencySingle


export const query = graphql`
  query AgencySingleQuery($slug: String!) {
  site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  us: contentfulAgencies(slug: { eq: $slug }, node_locale: {eq: "en-US"}) {
      title
      slug
  }
  ru: contentfulAgencies(slug: { eq: $slug }, node_locale: {eq: "ru"}) {
      title
      slug
  }
}
`;